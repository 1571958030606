import moment from 'moment';
import ApiService from './api.service';

// actions
export const GET_CHANNEL = 'getChannel';
export const GET_BROADCAST_CHANNEL = 'getBroadcastChannel';
export const GET_BROADCAST_BY_ID_CHANNEL = 'getBroadcastByIDChannel';

export const CREATE_CHANNEL = 'createChannel';
export const CREATE_BROADCAST_CHANNEL = 'createBroadcastChannel';
export const UPLOAD_IMAGE = 'uploadImage';

// mutations
export const SET_CHANNEL = 'setChannel';
export const SET_BROADCAST_CHANNEL = 'setBroadcastChannel';
export const SET_BROADCAST_BY_ID_CHANNEL = 'setBroadcastByIDChannel';


const state = {
  channel: {},
  broadcast_channel: {},
  broadcast_by_id_channel: {}
};

const getters = {
  channel(state) {
    return state.channel;
  },
  broadcast_channel(state) {
    return state.broadcast_channel;
  },
  broadcast_by_id_channel(state) {
    return state.broadcast_by_id_channel;
  },
};

const actions = {

  [CREATE_CHANNEL](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post(`api/vchat/v1/channels`, param.data)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [CREATE_BROADCAST_CHANNEL](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post(`api/vchat/v1/broadcast`, param.data)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [GET_CHANNEL](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/vchat/v1/channels', param)
        .then(async ({ data }) => {
          await context.commit(SET_CHANNEL, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [GET_BROADCAST_CHANNEL](context, { page, query }) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/vchat/v1/broadcast`, query)
        .then(async ({ data }) => {
          await context.commit(SET_BROADCAST_CHANNEL, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },


  [GET_BROADCAST_BY_ID_CHANNEL](context, { channel_id, user }) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/vchat/v1/broadcast/channel/${channel_id}`, {
        params: { ...user },
      })
        .then(async ({ data }) => {
          await context.commit(SET_BROADCAST_BY_ID_CHANNEL, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [UPLOAD_IMAGE](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post(`api/setting/v1/uploader/json`, param.data)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [SET_CHANNEL](state, data) {
    if (!data.count) {
      state.channel = {
        count: 0,
        rows: [],
      };
      return;
    }
    data.rows = data.rows.map((v) => {
      // console.log("Raw created_at value:", v.created_at);
      v.is_online_str = v.is_online ? "True" : "False";
      v.is_online_color = v.is_online ? "tag-success" : "tag-danger";
      v.status_str = v.status ? "Active" : "Inactive";
      v.status_color = v.status ? "tag-success" : "tag-danger";
      v.created_at_str = v.created_at
        ? moment(v.created_at).utcOffset(7).format("DD MMM YYYY, HH:mm")
        : "-";
      return v;
    });
    state.channel = data;
  },

  [SET_BROADCAST_CHANNEL](state, data) {
    if (!data.count) {
      state.broadcast_channel = {
        count: 0,
        rows: [],
      };
      return;
    }
    data.rows = data.rows.map((v) => {
      console.log("Raw created_at value:", v.created_at);
      v.is_online_str = v.is_online ? "True" : "False";
      v.is_online_color = v.is_online ? "tag-success" : "tag-danger";
      v.status_str = v.status ? "Active" : "Inactive";
      v.status_color = v.status ? "tag-success" : "tag-danger";
      v.created_at_str = v.created_at
        ? moment(v.created_at).utcOffset(7).format("DD MMM YYYY, HH:mm")
        : "-";
      return v;
    });
    state.broadcast_channel = data;
  },
  [SET_BROADCAST_BY_ID_CHANNEL](state, data) {
    state.broadcast_by_id_channel = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};